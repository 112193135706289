import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";
import checkStuff from "./CheckStuff";

import Header from "./Header";
import Map from "./map/Map";
import About from "./About";

//import Search from "./Search";

class App extends Component {
	elementDidMount() {
		this.props.fetchUser();
		checkStuff();
	}
	render() {
		return (
			<div>
				<BrowserRouter>
				
					<Header />
					<Routes>
					{/* <Route  path="/admin" element={<Admin/>} />
					<Route  path="/admin/newevent" element={<NewEvent/>} />
					<Route  path="/admin/events" element={<EventList/>} />
					<Route
						
						path="/admin/newlocation"
						element={<NewLocation/>}
					/>
					<Route
						
						path="/admin/locations"
						element={<LocationList/>}
					/>
					<Route
						
						path="/admin/newpeople"
						element={<NewPeople/>}
					/>
					<Route
						
						path="/admin/edit/location/:id"
						element={<NewLocation/>}
					/>
					<Route
						
						path="/admin/edit/event/:id"
						element={<NewEvent/>}
					/>
					<Route  path="/admin/people" element={<PeopleList/>} />
					<Route  path="/admin/edit/people/:id" element={<NewPeople/>}/> */}
					{/*					<Route  path="/search" element={<Search/>} />*/}
					<Route  path="/" element={<Map/>} />
					<Route path="/about" element={<About/>} />
					</Routes>
				</BrowserRouter>
			</div>
		);
	}
}
export default connect(null, actions)(App);
