// Header.jsx

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { sendEmail } from "../actions";
import ContactForm from "./Contact";
import AboutPopup from "./About";
import WelcomePopup from "./WelcomePopup";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePopup: null, // 'contact', 'about', or 'welcome'
      emailResponse: null,
    };
  }

  // Method to open the appropriate popup
  openForm = (popupType) => {
    console.log(`${popupType} Popup Opened!`);
    this.setState({
      activePopup: popupType,
    });
  };

  // Method to close the popup
  closeForm = () => {
    this.setState({
      activePopup: null,
      emailResponse: null,
    });
  };

  // Method to handle sending email from the contact form
  sendEmailForm = (name, email, message) => {
    console.log("Sending email:", { name, email, message });
    this.props
      .sendEmail(name, email, message, "email")
      .then(() => {
        // On success, set emailResponse to "Sent"
        this.setState({ emailResponse: "Sent" });
      })
      .catch(() => {
        // On error, set emailResponse to "Failed"
        this.setState({ emailResponse: "Failed" });
      });
  };

  componentDidMount() {
    // Check if user has already visited
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      // If not visited, show the welcome popup
      this.setState({ activePopup: "welcome" });
      // Set the flag so next time the popup won't be shown
      localStorage.setItem("hasVisited", "true");
    }
  }

  renderContent() {
    switch (this.props.auth.auth) {
      case null:
        return "Waiting";
      case false:
        return (
          <li id="login" key={"1"}>
            <a href="/auth/google" className="login">
              Log In
            </a>
          </li>
        );
      default:
        return [
          <li id="login" key={"1"}>
            <a href="/api/logout" className="login">
              Log Out
            </a>
          </li>,
        ];
    }
  }

  render() {
    return (
      <div id="nav" className="mb-3">
        <div className="left column">
          {/* About Popup Trigger */}
          <button
            onClick={() => this.openForm("about")}
            className="fb-link"
            aria-label="About"
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <i className="fa-regular fa-circle-user"></i>
          </button>
        </div>
        <div className="middle column">
          <div id="logo">
            <Link to={"/"}>
              <h1 data-value="TheHistoryOf">TheHistoryOf</h1>
            </Link>
          </div>
        </div>
        <div className="right column">
          {/* Contact Form Trigger */}
          <button
            onClick={() => this.openForm("contact")}
            className="fb-link"
            aria-label="Email"
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <i className="fa-regular fa-envelope"></i>
          </button>
        </div>

        {/* Render the ContactForm when activePopup is 'contact' */}
        {this.state.activePopup === "contact" && (
          <ContactForm
            formIsOpen={this.state.activePopup === "contact"}
            closeForm={this.closeForm}
            sendEmailForm={this.sendEmailForm}
            emailResponse={this.state.emailResponse}
          />
        )}

        {/* Render the AboutPopup when activePopup is 'about' */}
        {this.state.activePopup === "about" && (
          <AboutPopup
            isOpen={this.state.activePopup === "about"}
            closePopup={this.closeForm}
          />
        )}

        {/* Render the WelcomePopup when activePopup is 'welcome' */}
        {this.state.activePopup === "welcome" && (
          <WelcomePopup
            isOpen={this.state.activePopup === "welcome"}
            closePopup={this.closeForm}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(auth) {
  return { auth };
}

export default connect(mapStateToProps, { sendEmail })(Header);
