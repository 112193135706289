import React, { Component } from "react";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      nameError: "",
      emailError: "",
      messageError: "",
      showSuccessMessage: false, // Controls the success message visibility
    };
    this.validateEmail = this.validateEmail.bind(this);
  }

  // Email validation function
  validateEmail(email) {
    // Simple regex for email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  // Watch for changes in the emailResponse prop to show/hide success messages
  componentDidUpdate(prevProps) {
    // Check if the emailResponse prop has changed
    if (this.props.emailResponse !== prevProps.emailResponse) {
      if (this.props.emailResponse === "Sent") {
        // Show the success message and hide the form
        this.setState({ showSuccessMessage: true });
        // Hide success message (and close form) after 3 seconds
        this.successMessageTimeout = setTimeout(() => {
          this.setState({ showSuccessMessage: false });
          this.props.closeForm();
        }, 3000);
      } else if (this.props.emailResponse === "Failed") {
        // Optionally handle failure case
        alert("Failed to send message. Please try again later.");
      }
    }
  }

  componentWillUnmount() {
    // Clear any timeout if the component unmounts
    if (this.successMessageTimeout) {
      clearTimeout(this.successMessageTimeout);
    }
  }

  render() {
    return (
      <div
        className={this.props.formIsOpen ? "outer is-open" : "outer"}
        onClick={this.props.closeForm}
      >
        <div className="middleForm">
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            {/* Close Button */}
            <button
              type="button"
              className="close-button"
              onClick={this.props.closeForm}
            >
              X
            </button>

            {/* Form Title */}
            <h1>Contact Us</h1>

            {/* Form Description */}
            <p>
              Have questions or comments? I'd love to hear from you. Please fill out
              the form below, and I'll get back to you as soon as possible.
            </p>

            {/* Success Message */}
            {this.state.showSuccessMessage && (
              <div className="success-message">
                <p>Thank you for contacting us!</p>
              </div>
            )}

            {/* Contact Form - Hide it if success message is showing */}
            {!this.state.showSuccessMessage && (
              <form className="form-group">
                {/* Name Input */}
                <div className="mb-3">
                  <label htmlFor="contactName" className="form-label">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactName"
                    value={this.state.name}
                    onChange={(e) => {
                      const name = e.target.value;
                      this.setState({ name }, () => {
                        if (name.trim() === "") {
                          this.setState({ nameError: "Name is required." });
                        } else {
                          this.setState({ nameError: "" });
                        }
                      });
                    }}
                  />
                  {this.state.nameError && (
                    <div className="form-text text-danger">
                      {this.state.nameError}
                    </div>
                  )}
                </div>

                {/* Email Input */}
                <div className="mb-3">
                  <label htmlFor="contactEmail" className="form-label">
                    Email address:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="contactEmail"
                    aria-describedby="emailHelp"
                    placeholder="example@email.com"
                    value={this.state.email}
                    onChange={(e) => {
                      const email = e.target.value;
                      this.setState({ email }, () => {
                        if (email.trim() === "") {
                          this.setState({ emailError: "Email is required." });
                        } else if (!this.validateEmail(email)) {
                          this.setState({
                            emailError: "Please enter a valid email address.",
                          });
                        } else {
                          this.setState({ emailError: "" });
                        }
                      });
                    }}
                  />
                  <div id="emailHelp" className="form-text">
                    We'll never share your email with anyone else.
                  </div>
                  {this.state.emailError && (
                    <div className="form-text text-danger">
                      {this.state.emailError}
                    </div>
                  )}
                </div>

                {/* Message Input */}
                <div className="mb-3">
                  <label htmlFor="contactMessage" className="form-label">
                    Message:
                  </label>
                  <textarea
                    className="form-control"
                    id="contactMessage"
                    value={this.state.message}
                    onChange={(e) => {
                      const message = e.target.value;
                      this.setState({ message }, () => {
                        if (message.trim() === "") {
                          this.setState({
                            messageError: "Message is required.",
                          });
                        } else {
                          this.setState({ messageError: "" });
                        }
                      });
                    }}
                  ></textarea>
                  {this.state.messageError && (
                    <div className="form-text text-danger">
                      {this.state.messageError}
                    </div>
                  )}
                </div>

                {/* Send Button */}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    let isValid = true;

                    // Validate Name
                    if (this.state.name.trim() === "") {
                      this.setState({ nameError: "Name is required." });
                      isValid = false;
                    }

                    // Validate Email
                    if (this.state.email.trim() === "") {
                      this.setState({ emailError: "Email is required." });
                      isValid = false;
                    } else if (!this.validateEmail(this.state.email)) {
                      this.setState({
                        emailError: "Please enter a valid email address.",
                      });
                      isValid = false;
                    }

                    // Validate Message
                    if (this.state.message.trim() === "") {
                      this.setState({ messageError: "Message is required." });
                      isValid = false;
                    }

                    if (isValid) {
                      // Encode all fields before sending
                      const encodedName = encodeURIComponent(
                        this.state.name.trim()
                      );
                      const encodedEmail = encodeURIComponent(
                        this.state.email.trim()
                      );
                      const encodedMessage = encodeURIComponent(
                        this.state.message.trim()
                      );

                      // Proceed to send email or submit the form
                      this.props.sendEmailForm(
                        encodedName,
                        encodedEmail,
                        encodedMessage
                      );

                      // Clear the form fields
                      this.setState({
                        name: "",
                        email: "",
                        message: "",
                        nameError: "",
                        emailError: "",
                        messageError: "",
                      });
                    }
                  }}
                >
                  Send
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
