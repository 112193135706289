import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import markersReducer from "./markersReducer";
import authReducer from "./authReducer";
import eventReducer from "./eventReducer";
import locationReducer from "./locationReducer";
import peopleReducer from "./peopleReducer";
import searchReducer from "./searchReducer";
import mapinfoReducer from "./mapinfoReducer";
import emailReducer from "./emailReducer"

export default combineReducers({
	markers: markersReducer,
	auth: authReducer,
	form: reduxForm,
	event: eventReducer,
	location: locationReducer,
	people: peopleReducer,
	search: searchReducer,
	mapinfo: mapinfoReducer,
	emailResponse: emailReducer,
});
