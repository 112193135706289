import React, { Component } from "react";

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localYear: this.props.year,
    };
  }

  // When props.year changes (e.g., the parent updates it),
  // sync it to the local state so the input field updates accordingly.
  componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      this.setState({ localYear: this.props.year });
    }
  }

  handleChange = (e) => {
    this.setState({ localYear: e.target.value });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.props.setYearInput(this.state.localYear);
    }
  };

  render() {
    return (
      <div
        id="timeline"
        className={
          this.props.screenWidth <= 70
            ? "btn-group btn-group-sm"
            : "btn-group"
        }
        role="group"
      >
        <button
          type="button"
          className="btn"
          id="timelineBtn"
          value="-10"
          onClick={() => this.props.setYear(-10)}
        >
          <p>-10</p>
        </button>

        <button
          type="button"
          className="btn"
          id="timelineBtn"
          value="-1"
          onClick={() => this.props.setYear(-1)}
        >
          <p>-1</p>
        </button>

        {/* Only submit on Enter press */}
        <input
          type="text"
          className="form-control"
          name="yearInput"
          id="currentYear"
          value={this.state.localYear}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />

        <button
          type="button"
          className="btn"
          id="timelineBtn"
          value="1"
          onClick={() => this.props.setYear(1)}
        >
          <p>+1</p>
        </button>

        <button
          type="button"
          className="btn"
          id="timelineBtn"
          value="10"
          onClick={() => this.props.setYear(10)}
        >
          <p>+10</p>
        </button>
      </div>
    );
  }
}

export default Timeline;
