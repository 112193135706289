import "./components/style.css";
import "bootstrap/dist/css/bootstrap.min.css"
import React from "react";
import {createRoot} from "react-dom/client"
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";

import App from "./components/App";
import reducers from "./reducers";

//const store = createStore(reducers, {}, applyMiddleware(reduxThunk), +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 const store = createStore(reducers, {}, composeEnhancers(
    applyMiddleware(reduxThunk)
  ));

// Create the root element
const container = document.querySelector("#root");
const root = createRoot(container);

// Render the app
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);