import React, { Component } from "react";

class Popup extends Component {
  render() {
    const { popupIsOpen, popupContent, mapInfo, openFeedbackForm } = this.props;

    // Determine which title to display
    const titleText = popupContent.Name && popupContent.Name.trim()
      ? popupContent.Name
      : popupContent.Address || ""; // fallback to empty string if Address is also undefined

    return (
      <div className={popupIsOpen ? "popupContainer is-open" : "popupContainer"}>
        <div className="popupInfo">
          <div className="card bg-white text-black">
            <div className="popupHeader">
              <div
                className="popupImage"
                style={{
                  backgroundImage: `url(${mapInfo.image})`,
                }}
              >
                {/* Image Source Overlay */}
                {mapInfo.imgSource && mapInfo.imgSrcUrl && (
                  <div className="imageSource">
                    Image source:{" "}
                    <a
                      href={mapInfo.imgSrcUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {mapInfo.imgSource}
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              <h5 className="card-title">
                <strong>{titleText}</strong>
              </h5>
              <p className="card-text">
                <strong>
                  {mapInfo.category === "street" || mapInfo.category === "building"
                    ? "Built:"
                    : "Date:"}{" "}
                </strong>
                {popupContent.start_year}
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: mapInfo.description,
                }}
              />

              {/* New Section for Sources with Numbers */}
              {mapInfo.sources && mapInfo.sources.length > 0 && (
                <div className="sources">
                  <strong>Sources:</strong>
                  <ol>
                    {mapInfo.sources.map((source, index) => (
                      <li key={index}>
                        <a
                          href={source.sourceUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {source.sourceName}
                        </a>
                      </li>
                    ))}
                  </ol>
                </div>
              )}

              <div>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={openFeedbackForm}
                >
                  Send feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;
