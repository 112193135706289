// WelcomePopup.jsx
import React from "react";

class WelcomePopup extends React.Component {
  render() {
    const { isOpen, closePopup } = this.props;

    return (
      <div className={isOpen ? "outer is-open" : "outer"} onClick={closePopup}>
        <div className="middleForm">
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            {/* Close Button */}
            <button type="button" className="close-button" onClick={closePopup}>
              X
            </button>

            {/* Welcome Title */}
            <h1>Welcome to TheHistoryOf!</h1>

            {/* Welcome Content */}
            <p>
              Explore the history of early Toronto with an interactive map. Use the 
              <strong> +/- 1 & 10</strong> buttons to navigate through the years and watch the city evolve. 
              Watch the streets grow and the shoreline change! Click on streets and buildings to uncover details about their past. I hope you enjoy your journey through time! 
            </p>
            <p>
            This is an ongoing project dedicated to mapping and cataloging Toronto's early history. This is version 0.1 of the site, and it is far from complete. There is still 
            lots of missing content to be filled in, and updates will be made regularly to improve and 
            expand the map.
          </p>
            <p>
              As this is a solo endeavor, there may be (and likely are) a few mistakes along the way. 
              If you have any questions, feedback, or corrections, please don’t hesitate to 
              contact me with the mail button above. 
              Enjoy your journey through Toronto’s history!
            </p>
            <p>
            -Michael G
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default WelcomePopup;
