import React from "react";

class AboutPopup extends React.Component {
  render() {
    return (
      <div
        className={this.props.isOpen ? "outer is-open" : "outer"}
        onClick={this.props.closePopup}
      >
        <div className="middleForm">
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            {/* Close Button */}
            <button
              type="button"
              className="close-button"
              onClick={this.props.closePopup}
            >
              X
            </button>

            {/* About Title */}
            <h1>About TheHistoryOf</h1>

            {/* About Content */}
            
              <p>
                Welcome to <strong>TheHistoryOf</strong>, a website dedicated to uncovering and celebrating 
                Toronto’s early history through an engaging interactive map. Step back in time to explore what 
                Toronto looked like in its formative years. Watch as the streets expand and the shoreline transforms. 
                I hope you enjoy your journey through the past!
              </p>
              <p>
                This project began as a big idea: to map and catalog the history of the entire world, showing how 
                countries and cities evolved over time. While that turned out to be a bit too ambitious, I decided 
                to focus closer to home, on the history of my own city, Toronto.
              </p>
              <p>
                Growing up here, my parents often took us on adventures around Toronto, sharing fascinating stories 
                and old photos of what the city once was. Their stories sparked my lifelong curiosity about the history 
                that shaped Toronto into the vibrant place it is today.
              </p>
              <p>
                This project is my way of passing on that curiosity and sharing the details that make Toronto what it is 
                today. While this site is still a work in progress, and may never truly be “finished," I’m excited to keep 
                adding to it with new discoveries and information.
              </p>
              <p>
                Thank you for visiting!
              </p>
              <p>
                -Michael G
              </p>
              <h2>Resources</h2>
              <p>
                Here are some resources that have helped me put the project together.
              </p>
              <p>
                <ul>
                  <li>
                  Nathan Ng's <a href="" target="_blank">Historical Maps of Toronto</a> was an invaluable resource in finding a lot of the maps used to map out this project.
                  </li>
                  <li>
                    Wendy Smith's <a href="https://parklotproject.com/" target="_blank">Toronto Park Lot Project.</a>
                  </li>
                  <li>
                  <a href="http://www.torontohistory.net/" target="_blank">Toronto Historical Association</a>
                  </li>
                  <li>
                    The Architectural Conservancy of Ontario <a href="https://www.acotoronto.ca/tobuilt.php" target="_blank">TObuilt</a> database.
                  </li>
                  <li>
                    <a href="https://www.lostrivers.ca/" target="_blank">Lost Rivers Project</a>
                  </li>
                </ul>
              
              </p>
            {/* Add more about content as needed */}
          </div>
        </div>
      </div>
    );
  }
}

export default AboutPopup;