import React, { Component } from "react";
import { connect } from "react-redux";
import mapboxgl from "mapbox-gl";
import MapContext from "../../mapContext";
import { fetchMarkers, clickedMarker, fetchMapinfo, sendEmailFeedback } from "../../actions";
import Timeline from "./Timeline";
import Popup from "./Popup";
import Feedback from "./Feedback"
import {isMobile} from 'react-device-detect';

//import Categories from "./Categories";

mapboxgl.accessToken =
	"pk.eyJ1IjoibWlrZWcwNDEyIiwiYSI6ImNqZms2ZG5uejAxcmczM280bDk5YWM4b3MifQ.rdzFMON3UasrZZF9eeTPNA";

class Map extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// 	markers: [],
			popupContent: {},
			popupIsOpen: false,
			feedbackIsOpen: false,
			mapinfo: [{ name: "", image: "" }],
			year: 1792,
			lng: 0,
			lat: 0,
			zoom: 0,
			emailResponse: null,
			// 	categories: [
			// 		{ name: "government", active: true },
			// 		{ name: "social", active: true },
			// 		{ name: "military", active: true },
			// 		{ name: "economic", active: true },
			// 		{ name: "religion", active: true },
			// 	],
			// 	activeCategories: [],
		};
		// this.setIsOpen = this.setIsOpen.bind(this);
	}

	componentDidMount() {


		//let year = 1792;
		
		this.map = new mapboxgl.Map({
			container: this.mapContainer,
			style: "mapbox://styles/mikeg0412/cjib2o9th0zuk2rufvmu0tdyw",
			center: [-79.377, 43.638],
			zoom: 12,
			maxBounds: [[ -79.56317302997276, 43.54665343617856], [-79.23731615685386, 43.77366889939714]],
		});

		this.map.on("load", () => {
			this.map.addSource("jsondata", {
				type: "geojson",
				data: this.props.markers,
			});
			this.map.addLayer({
				id: "jsondata",
				type: "symbol",
				source: "jsondata",
				layout: {
					"text-field": ["get", "title"],
					"text-font": [
						"Open Sans Semibold",
						"Arial Unicode MS Bold",
					],
					"text-size": 12,
					"text-offset": [1.25, 0],
					"text-optional": true, //Will only show text if marker text does not collide with another
					"text-anchor": "left",
					"icon-image": ["get", "icon"],
					"icon-allow-overlap": true,
					"icon-size": ["get", "iconSize"],
				},
			});
			//this.categoriesTrue();
			this.mapFilter();
			this.checkMobile();
			//on map load get params from url and pass them to functions to set the map.
			const searchParams = new URLSearchParams(window.location.search);
			let yearURL = searchParams.get('year');
			let lnURL = searchParams.get('lng');
			let laURL = searchParams.get('lat');
			let zURL = searchParams.get('z');
			if(yearURL){
				this.setLngLatZoom(lnURL,laURL,zURL);
				this.setYearURL(yearURL);
			}
			
		});
		//Closes popup and update page title back to default
		this.map.on("click", () => {
			let popupInfo = this.state.popupContent;
			let popupMapInfo = this.state.minfo;
			if (this.state.popupIsOpen) {
				this.returnPopupInfo(popupInfo, false, popupMapInfo);
				document.title = `TheHistoryOf..`;
			}
			console.log(popupInfo)
		});

		//Update popup info and page title with name of item clicked and current year
		this.map.on("click", "jsondata", (e) => {
			let popupInfo = e.features[0].properties;
			//console.log(popupInfo)
			document.title = `TheHistoryOf.. ${popupInfo.title} - ${popupInfo.start_year}`;
			this.props.clickedMarker(popupInfo.id);
			this.returnPopupInfo(popupInfo, true);
		});

		// 		this.map.on("mouseenter", "jsondata", () => {
		// 			this.map.getCanvas().style.cursor = "pointer";
		// 		});

		// 		// Change it back to a pointer when it leaves.
		// 		this.map.on("mouseleave", "jsondata", () => {
		// 			this.map.getCanvas().style.cursor = "";
		// 		});

		//Feature to click on roads or buildings and get the properties to display info about it. Fetches data from Mongo about feature. Set page Title to feature name and current year.
		this.map.on("click", (e) => {
			const features = this.map.queryRenderedFeatures(e.point);
			for (let i = 0; i < features.length; i++) {
			  if (
				features[i].layer.id === "streets" ||
				features[i].layer.id === "buildings" ||
				features[i].layer.id === "streets-mobile"
			  ) {
				let popupInfo = features[i].properties;
		  
				// Check if popupInfo.Name exists and is not null or undefined
				if (popupInfo.Name != ' ') {
				  this.setState({
					mapinfo: this.props.fetchMapinfo(popupInfo.Name, popupInfo.start_year),
				  });
				  this.returnPopupInfo(popupInfo, true);
				  document.title = `TheHistoryOf.. ${popupInfo.Name} - ${popupInfo.start_year}`;
				} else {
				  // Handle the case where Name is null or undefined
				  this.setState({ mapinfo: null });
				  this.returnPopupInfo(popupInfo, true);
				  document.title = `TheHistoryOf..`;
				}
				//console.log(features[i].properties);
			  }
			}
		  });

		const mouseLayers = ["streets", "buildings"]
		this.map.on("mouseenter", mouseLayers, () => {
			this.map.getCanvas().style.cursor = "pointer";
		});

		this.map.on("mouseleave", mouseLayers, () => {
			this.map.getCanvas().style.cursor = "";
		});
		
		this.map.on("moveend",()=>{
			const {lng,lat} = this.map.getCenter();
			const zoom = this.map.getZoom();
			this.setLngLat(lng,lat,zoom);
			// console.log(lng,lat)
			// console.log(this.map.getBounds());
		})
		
	}

checkMobile = () => {
	if(!isMobile){
		this.map.setLayoutProperty("streets-mobile","visibility","none")
	}
	//console.log(isMobile);
};

mapFilter = () => {
	//Removed layers "paths", "paths-shadow", "builtland"
	const mapLayers = [
		"streets",
		"streets-shadow",
		"streets-label",
		"streets-mobile",
		"buildings",
		"buildings-label",
		"walls",
		"poi",
		"town-label",
		"waterline-label",
		"waterline"
	];
	for (let i = 0; i < mapLayers.length; i++) {
		this.map.setFilter(mapLayers[i], [
			"all",
			["<=", "start_year", this.state.year],
			[">=", "end_year", this.state.year],
		]);
	}
};

setIsOpen() {
	return this.setState({
		//popupContent: [],
		popupIsOpen: false,
	});
}

// setCategories = (name) => {
// 	const newCategories = this.state.categories;
// 	for (let i in newCategories) {
// 		if (newCategories[i].name === name) {
// 			newCategories[i].active = !newCategories[i].active;
// 			break;
// 		}
// 	}
// 	this.setState({
// 		categories: newCategories,
// 	});
// 	this.categoriesTrue();
// };

// categoriesTrue = () => {
// 	let categories = this.state.categories;
// 	let newActiveCategories = [];
// 	for (let i in categories) {
// 		if (categories[i].active === true) {
// 			newActiveCategories.push(categories[i].name);
// 		}
// 	}
// 	this.setState({
// 		activeCategories: newActiveCategories,
// 	});
// };

//Function to set state for lat, lng, and zoom for URL
setLngLat = (lng,lat,zoom) => {
	return this.setState({
		lng: lng.toFixed(6),
		lat: lat.toFixed(6),
		zoom: zoom.toFixed(2),
	});
}

//Function to set the mapt to the lat, lng and zoom from the URL
setLngLatZoom = (lng,lat,zoom) => {
	this.map.setCenter([lng, lat]);
	this.map.setZoom(zoom);
}
setYearURL = (value) => {
	return this.setState({
		year: Number(value),
	})
}

setYear = (value) => {
	
	let currentYear = this.state.year;
	currentYear += value;
	if(currentYear > 1850){
		return this.setState({
			year: 1850,
		});
	} else if(currentYear < 1790) {
		return this.setState({
			year: 1790,
		});
	} else {
		return this.setState({
			year: currentYear,
		});
	}
	
};

openFeedbackForm = () => {
	//console.log("Feedback Open!")
	return this.setState({
		feedbackIsOpen: true,
		//popupContent: [],
		popupIsOpen: false,
	})
}
closeFeedbackForm = () => {
	return this.setState({
		feedbackIsOpen: false,
		emailResponse: '',
	})
}

setYearInput = (value) => {
	let currentYear = value;
	if(currentYear > 1850){
		return this.setState({
			year: 1850,
		});
	} else if(currentYear < 1790) {
		return this.setState({
			year: 1790,
		});
	} else {
		return this.setState({
			year: Number(currentYear),
		});
	}
};

sendEmail = (name, email, message, sources) => {
    if (!email || !message) {
      // Handle missing email or message
      //console.error('Email and message are required.');
    } else {
		this.setState({emailResponse: this.props.sendEmailFeedback(
			      name,
			      email,
			      message,
			      sources,
			      this.state.popupContent.Name,
			      this.state.year,
			      this.state.lng,
			      this.state.lat,
			      this.state.zoom,
			      'feedback'
			    ),})
    //   try {
    //     const response = await this.props.sendEmailFeedback(
    //       name,
    //       email,
    //       message,
    //       sources,
    //       this.state.popupContent.Name,
    //       this.state.year,
    //       this.state.lng,
    //       this.state.lat,
    //       this.state.zoom,
    //       'feedback'
    //     );

    //     // Update the state with the API response
	// 	console.log(response);
    //     this.setState({ emailResponse: response });
    //   } catch (error) {
    //     // Handle any errors from the API call
    //     console.error('Error sending email:', error);
    //     this.setState({ emailResponse: 'Failed' });
    //   } finally {
    //     // Close the feedback form after the API call completes
    //     //this.closeFeedbackForm();
    //   }
    }
  };

componentDidUpdate(prevProps, prevState) {
	if (this.props.markers !== prevProps.markers) {
		//this.map.getSource("jsondata").setData(this.props.markers);
	}
	if (
		this.state.year !== prevState.year ||
		this.state.activeCategories !== prevState.activeCategories
	) {
		// this.props.fetchMarkers(
		// 	this.state.year,
		// 	this.state.activeCategories
		// );
		this.mapFilter();
		window.history.pushState({},'',`/?year=${this.state.year}&lng=${this.state.lng}&lat=${this.state.lat}&z=${this.state.zoom}`)
	}
	if(this.state.lng !== prevState.lng || this.state.lat !== prevState.lat){
		window.history.pushState({},'',`/?year=${this.state.year}&lng=${this.state.lng}&lat=${this.state.lat}&z=${this.state.zoom}`)
	}
	if (this.props.mapinfo !== prevProps.mapinfo) {
		this.setState({ mapinfo: this.props.mapinfo });
		//console.log(this.props.mapinfo);
	}
	if (this.props.emailResponse !== prevProps.emailResponse) {
		//console.log(this.props.emailResponse)
		this.setState({ emailResponse: this.props.emailResponse });
	}
}

returnPopupInfo = (info, isOpen) => {
	return this.setState({
		popupContent: info,
		popupIsOpen: isOpen,
	});
};

render() {

	return (
		<>
			<div>
				<div
					ref={(el) => (this.mapContainer = el)}
					className="mapContainer"
				/>
			</div>
			<MapContext.Provider value={this.map}>
				<Popup
					popupContent={this.state.popupContent}
					popupIsOpen={this.state.popupIsOpen}
					setIsOpen={this.setIsOpen}
					mapInfo={this.props.mapinfo[0]}
					feedbackIsOpen={this.state.feedbackIsOpen}
					openFeedbackForm={this.openFeedbackForm}

				/>
				{/*<Categories
						setCategories={this.setCategories}
						categories={this.state.categories}
						screenWidth={this.state.screenWidth}
					/>*/}

				<Timeline
					popup={this.state.popup}
					setIsOpen={this.setIsOpen}
					year={this.state.year}
					setYear={this.setYear}
					screenWidth={this.state.screenWidth}
					setYearInput={this.setYearInput}
				/>
				<Feedback
					feedbackIsOpen={this.state.feedbackIsOpen}
					closeFeedbackForm={this.closeFeedbackForm}
					sendEmail={this.sendEmail}
					emailResponse={this.state.emailResponse} // Pass the response to Feedback
				/>
			</MapContext.Provider>
		</>
	);
}
}

function mapStateToProps(state) {
	return { markers: state.markers, mapinfo: state.mapinfo, emailResponse: state.emailResponse };
}

export default connect(mapStateToProps, { fetchMarkers, fetchMapinfo, clickedMarker, sendEmailFeedback })(Map);
