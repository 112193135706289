import axios from "axios";
import {
	FETCH_MARKERS,
	FETCH_USER,
	FETCH_EVENT,
	FETCH_LOCATION,
	FETCH_PEOPLE,
	FETCH_MAPINFO,
	SEARCH_LOCATION,
	FETCH_EMAIL,
} from "./types";

//MapInfo Action

export const fetchMapinfo = (name,startYear) => async (dispatch) => {
	const res = await axios.get(`/api/mapinfo/${name}/${startYear}`);
	dispatch({type: FETCH_MAPINFO, payload: res.data});
}

//Email Actions

export const sendEmailFeedback = (name,email,message,sources,popup,year,lng,lat,zoom, type) => async (dispatch) => {
	const res = await axios.get(`/api/emailFeed/${name}/${email}/${message}/${sources}/${popup}/${year}/${lng}/${lat}/${zoom}/${type}`);
	dispatch({type: FETCH_EMAIL, payload: res.data});
	console.log(res.data)
}
export const sendEmail = (name,email,message,type) => async (dispatch) => {
	const res = await axios.get(`/api/email/${name}/${email}/${message}/${type}`);
	dispatch({type: FETCH_EMAIL, payload: res.data});
}

//Marker Actions

export const fetchMarkers = (year, category) => async (dispatch) => {
	if (Array.isArray(category) && category.length) {
		const res = await axios.get(`/api/year/${year}/category/${category}`);
		dispatch({ type: FETCH_MARKERS, payload: res.data });
	} else {
		const res = await axios.get(`/api/year/${year}/category/null`);
		dispatch({ type: FETCH_MARKERS, payload: res.data });
	}
};

export const clickedMarker = (id) => async (dispatch) => {
	const res = await axios.post(`/api/clicked/${id}`);
}

//User Actions

export const fetchUser = () => async (dispatch) => {
	const res = await axios.get("/api/current_user");
	dispatch({ type: FETCH_USER, payload: res.data });
};

//Event Actions

export const submitEvent = (values) => async (dispatch) => {
	const res = await axios.post("/api/newevent", values);
	dispatch({ type: FETCH_EVENT, payload: res.data });
};

export const editEvent = (id) => async (dispatch) => {
	const res = await axios.get(`/api/edit/event/${id}`);
	dispatch({ type: FETCH_EVENT, payload: res.data });
};

export const fetchEvents = () => async (dispatch) => {
	const res = await axios.get("/api/events");
	dispatch({ type: FETCH_EVENT, payload: res.data });
};

export const updateEvent = (values) => async (dispatch) => {
	const res = await axios.put(`/api/update/event/${values._id}`, values);
	dispatch({ type: FETCH_EVENT, payload: res.data });
};

export const removeField = (id, name, values) => async (dispatch) => {
	const res = await axios.post(
		`/api/remove/event/field/${id}/${name}/${values._id}`,
		values
	);
	dispatch({ type: FETCH_EVENT, payload: res.data });
};

//Location Actions

export const newLocation = (values) => async (dispatch) => {
	const res = await axios.post("/api/newlocation", values);
	dispatch({ type: FETCH_LOCATION, payload: res.data });
};

export const fetchLocations = () => async (dispatch) => {
	const res = await axios.get(`/api/locations`);
	dispatch({ type: FETCH_LOCATION, payload: res.data });
};

export const editLocation = (id) => async (dispatch) => {
	const res = await axios.get(`/api/edit/location/${id}`);
	dispatch({ type: FETCH_LOCATION, payload: res.data });
};

export const updateLocation = (id, values) => async (dispatch) => {
	const res = await axios.put(`/api/update/location/${id}`, values);
	dispatch({ type: FETCH_LOCATION, payload: res.data });
};

//People Actions

export const newPeople = (values) => async (dispatch) => {
	const res = await axios.post("/api/newpeople/", values);
	dispatch({ type: FETCH_PEOPLE, payload: res.data });
};

export const fetchPeople = () => async (dispatch) => {
	const res = await axios.get("/api/people");
	dispatch({ type: FETCH_PEOPLE, payload: res.data });
};

export const editPeople = (id) => async (dispatch) => {
	const res = await axios.get(`/api/edit/people/${id}`);
	dispatch({ type: FETCH_PEOPLE, payload: res.data });
};

export const updatePeople = (id, values) => async (dispatch) => {
	const res = await axios.put(`/api/update/people/${id}`, values);
	dispatch({ type: FETCH_PEOPLE, payload: res.data });
};
